
// ButtonGroup
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button-group {
	display: inline-block;

	.button,
	button {
		border-radius: 0;

		&:first-child {
			border-top-left-radius: $button-border-radius;
			border-bottom-left-radius: $button-border-radius; }

		&:not(:first-child) {
			border-left: none; }

		&:last-child {
			border-top-right-radius: $button-border-radius;
			border-bottom-right-radius: $button-border-radius; } } }
