
// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: rgb(9, 119, 186) !default;
$color-secondary: #333 !default;
$color-tertiary: lighten($color-primary, 10%) !default;
$color-quaternary: darken($color-primary, 15%) !default;
$color-quinary: lighten($color-primary, 20%) !default;
$color-senary: lighten($color-secondary, 10%) !default;
