
// Link
// ––––––––––––––––––––––––––––––––––––––––––––––––––

a {
	color: $color-primary;
	text-decoration: none;

	&:focus,
	&:hover {
		color: $color-secondary; } }
