// Input Range Slider
// ––––––––––––––––––––––––––––––––––––––––––––––––––

input[type="range"] {
    -webkit-appearance: none;
    background-color: $color-primary;
    display: inline-block;
    height: 3.8rem;
    border-radius: 0.4rem;
    line-height: 3.8rem;
    vertical-align: middle;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 2px;

    &:focus {
        outline: none; }

    &::-moz-range-track,
    &::-webkit-slider-runnable-track {
        width: 100%;
        cursor: pointer; }

    &::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 0.2rem;
        background: white; }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 0.2rem;
        background: white; } }
